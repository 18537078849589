



























import { Vue, Component, Prop } from "vue-property-decorator";
import { MobilePendingOrderProperties } from "./MobilePendingOrderAView.vue";

@Component({})
export default class MobilePendingSummaryView extends Vue {
    @Prop() private properties: MobilePendingOrderProperties;

    public get pendingOrders() {
        return this.properties.pendingOrders ?? [];
    }

    public get currency() {
        return this.properties.currency ?? {};
    }

    public get totalQuantity() {
        var totalQuantity = 0;
        
        for (var i = 0; i < this.pendingOrders.length; i++) {
            totalQuantity += this.pendingOrders[i].quantity;
        }

        return totalQuantity;
    }

    public get totalAmount() {
        var totalAmount = 0;

        for (var i = 0; i < this.pendingOrders.length; i++) {
            totalAmount += this.pendingOrders[i].totalAmount;
        }

        return totalAmount;
    }
}
